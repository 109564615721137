<template>
  <b-card no-body>
    <visual-database-add-row :is-add-new-row-sidebar-active.sync="isAddNewRowSidebarActive"
      @refetch-data="clearRevoValues" />

    <visual-database-download-modal></visual-database-download-modal>

    <div v-if="isLoading" class="my-3">
      <custom-loading></custom-loading>
    </div>
    <div v-else>

      <b-card-body>
        <b-card-title class="text-center">
        <br>
          
        <h1>S&OP {{ cabecera }}</h1>
      </b-card-title>
        <b-row class="mb-1">
          <b-button @click="resetFilter" variant="primary" class="ml-1">
            <b-icon-arrow-counterclockwise/>  Reset Filters
          </b-button>

          <b-button @click="saveData" :disabled="isDisabledSaveData" variant="primary" class="ml-1">
            <b-icon-arrow-down-circle-fill/>  Save
          </b-button>

          <b-button @click="isAddNewRowSidebarActive = true" variant="primary" class="ml-1">
            <b-icon-plus-circle-fill/>  Add Row
          </b-button>

          <b-button v-b-modal.visual-database-download variant="primary" class="ml-1">
            <b-icon-download/>  Download
          </b-button>


          <b-button  @click="cargarData" variant="primary" class="ml-1">
            <b-icon-upload/>  Cargar Simulador
          </b-button>
        </b-row>
        <b-row style="padding: 13px">
          <v-grid ref="refGrid" :source="source" :columns="columns" theme="material" rowHeaders="true" resize="true" autoSizeColumn=true
            range="true" filter="true" :columnTypes="pluginRevo" class="grid-container-visual-database"  
            @beforeeditstart="beforeEditStart" @focusout="commitCell" ></v-grid>
        </b-row>
      </b-card-body>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BCardBody,
  BRow, BCardTitle, BIconArrowCounterclockwise,BIconArrowDownCircleFill,BIconPlusCircleFill,BIconDownload,BIconUpload
} from 'bootstrap-vue'
import { ref, getCurrentInstance, computed, watch } from 'vue'
import VGrid from '@revolist/vue-datagrid';
import axios from '@axios'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import VisualDatabaseAddRow from './VisualDatabaseAddRow.vue'
import VisualDatabaseDownloadModal from './VisualDatabaseDownloadModal.vue'
import NumberColumnType from '@revolist/revogrid-column-numeral'
import gql from 'graphql-tag'
import { numericSort } from '@/libs/revo-grid/custom-sort'

export default {
  components: {
    BRow,
    BCard,
    BButton,
    BCardBody,
    BCardTitle,
    CustomLoading,
    VisualDatabaseAddRow,
    VisualDatabaseDownloadModal,
    VGrid,
    BIconArrowCounterclockwise,BIconArrowDownCircleFill,BIconPlusCircleFill,BIconDownload,BIconUpload
  },
  setup() {
    const isAddNewRowSidebarActive = ref(false)
    const source = ref([])
    const isLoading = ref(true)
    const refGrid = ref(null)
    const columns = ref([])
    const revoRowIndex = ref(null)
    const revoColName = ref(null)
    const modifiedData = ref([])
    let lastID = ref({})
    let cabecera = ref(null)

    const pluginRevo = { 'numeric': new NumberColumnType('0,0') }

    const vm = getCurrentInstance().proxy

    const clearRowSelection = () => {
      revoRowIndex.value = null
      revoColName.value = null
    }

    const clearRevoValues = () => {
      clearRowSelection()
      modifiedData.value = []
    }

    const resetFilter = () => {
      refGrid.value.$el.getPlugins().then((plugins) => {
        plugins.forEach((p) => {
          if (p.clearFiltering) {
            p.clearFiltering()
            clearRowSelection()
          }
        })
      })
    }

    const cargarData = () => {
      vm.$swal({
        title: `¿Está seguro de ejecutar el proceso Generar Data SOP?`,
        text: 'Si hay datos en el mes en curso, estos se borrarán y se volverá a ingresar otra vez los datos',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        backdrop: false,
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios
          //.post(`http://127.0.0.1:3100/api/cargar_bd_presimulador`)
            .post(`${process.env.VUE_APP_BASE_URL}/cargar_bd_presimulador`,
            {"id": cabecera})

            .then(response => {
              if (response.status != 200) {
                throw new Error(response.statusText)
              }
              return response
            })
            .catch(error => {
              vm.$swal.showValidationMessage(`Request failed:  ${error}`)
            })
        },
      }).then(async (result) => {
        if (result.value) {
          vm.$swal({
            icon: 'success',
            title: 'Proceso proceso Generar Data SOP Ejecutado!',
            customClass: {
              confirmButton: 'btn btn-success',
            }
          })
        }
      })
    }

    const beforeEditStart = (event) => {
      revoRowIndex.value = event.detail.rowIndex
      revoColName.value = event.detail.prop
    }


    const commitCell = async (event) => {
      const originalData = await refGrid.value.$el.getSource()
      const viewData = await refGrid.value.$el.getVisibleSource()
      let rowData = viewData[revoRowIndex.value]
      let oldValue = rowData[revoColName.value]
      let newValue = event.target.value
      if (oldValue != newValue) {
        rowData[revoColName.value] = newValue
        modifiedData.value.push(Object.assign({}, rowData))
        refGrid.value.$el.source = [...originalData]
      }
      clearRowSelection()
    }

    
    
    const saveData = async () => {
      if (modifiedData.value.length > 0) {
        vm.$swal({
          title: `¿Está seguro de que desea guardar los cambios?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
          preConfirm() {
            return axios
              .post(`${process.env.VUE_APP_BASE_URL}/update_inputs`, {
                "data": modifiedData.value
              })
              .then(response => {
                if (response.status != 200) {
                  throw new Error(response.statusText)
                }
                return response
              })
              .catch(error => {
                vm.$swal.showValidationMessage(`Request failed:  ${error}`)
              })
          },
        }).then(async (result) => {
          if (result.value) {
            vm.$swal({
              icon: 'success',
              title: 'Datos guardados!',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              willClose: clearRevoValues
            })
          }
        })
      }
    }


    // subscription 
    columns.value = [
      {
        "name": "ID",
        "prop": "id",
        "size": 120,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "CLASIFICACION",
        "prop": "clasificacion",
        "size": 170,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "CANAL",
        "prop": "canal",
        "size": 120,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "BPU",
        "prop": "BPU",
        "size": 150,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "NART",
        "prop": "nart",
        "size": 200,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "SPGR",
        "prop": "SPGR",
        "size": 120,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "DESCRIPCION",
        "prop": "spgrdesc",
        "size": 400,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "YEAR",
        "prop": "year",
        "size": 100,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "MONTH",
        "prop": "month",
        "size": 120,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "BRAND CATEGORY",
        "prop": "BrandCategory",
        "size": 200,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "APPLICATION FORM",
        "prop": "ApplicationForm",
        "size": 210,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "UNITS",
        "prop": "units",
        "size": 120,
        "autoSize": true,
        "sortable": true,
        "readonly": false,
        "cellCompare": numericSort,
        "columnType": 'numeric',
      },
      {
        "name": "NETSALES",
        "prop": "netsales",
        "size": 140,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'numeric',
      },
      {
        "name": "NS_M-1",
        "prop": "ns_m1",
        "size": 140,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'numeric',
      },
      {
        "name": "NS_M-2",
        "prop": "ns_m2",
        "size": 140,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'numeric',
      }

    ]

    vm.$apollo.addSmartSubscription('getVisualDatabase', {
      query: gql`
        subscription {
          BD_LastID(order_by: {month: asc, year: asc}) {
            id
            clasificacion
            canal
            BPU
            nart
            SPGR
            spgrdesc
            year
            month
            BrandCategory
            ApplicationForm
            units
            netsales
            ns_m1
            ns_m2
          }
        }
      `,
      result({ data }) {
        isLoading.value = false
        source.value = data.BD_LastID
        lastID =  source.value[0]
        cabecera.value = lastID['id']

      },
    })

    // computed
    const isDisabledSaveData = computed(() => {
      return modifiedData.value.length <= 0
    })
   
    //onCreated
    clearRevoValues()
  
    return {
      isAddNewRowSidebarActive,
      source,
      columns,
      isLoading,
      refGrid,
      pluginRevo,

      resetFilter,
      beforeEditStart,
      commitCell,
      saveData,
      lastID,
      cabecera,
      clearRevoValues,
      isDisabledSaveData,
      cargarData,
    }
  },
}
</script>

<style lang="scss">
.grid-container-visual-database {
  width: 100%;
  height: 80vh;
  font-family: "Montserrat", Helvetica, Arial, serif !important;

  .temp-bg-range {
    display: block !important;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    border: 1px solid #ff5e00;
    box-sizing: border-box
  }

  .header-rgRow {
    background: #0032a0;
    color: white;
  }

  .rgRow .disabled {
    background: none;
  }

  .rgHeaderCell.sortable:hover {
    background-color: #f1f1f1;
    color: black;
  }
}

@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
